import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import AppRoutes from './routes';
import theme from './styles/theme';

const App = () => {
  useEffect(() => {
    // Redireciona para o domínio especificado
    window.location.href = 'https://aplicativo.apphelix.com.br';
  }, []);

  return null; // Não renderiza nada, pois o redirecionamento será feito imediatamente
};

export default App;
